import Alert from "components/Alert/Alert";
import PublicLayoutPages from "components/PublicLayoutPages/PublicLayoutPages";
import { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import { Spinner, ThemeToggle } from "./components";
import { Error404 } from "./pages/errors";
import PrivateLayoutPages from "components/PrivateLayoutPages/PrivateLayoutPages";
import Logout from "pages/Logout/Logout";

import { add } from "store/reducers/engReducer";
import { useOracleAxiosWithAuth } from "Hooks/useOracleAxiosWithAuth";


const Login = lazy(() => import("./pages/auth/Login/Login"));

const Dashboard = lazy(() =>
  import("./pages/__PrivatePages/Dashboard/Dashboard")
);

const Settings = lazy(() => import("./pages/__PrivatePages/Settings/Settings"));
const Personal = lazy(() => import("./pages/__PrivatePages/personal"));
const Prsn = lazy(() => import("./pages/__PrivatePages/personal/prsn"));
const Practices = lazy(() => import("./pages/__PrivatePages/finance/practices"));
const FixReq = lazy(() => import("./pages/__PrivatePages/yields/fix-req"));
const Calculation = lazy(() => import("./pages/__PrivatePages/yields/calculation"));
const Union = lazy(() => import("./pages/__PrivatePages/personal/union"));
const Info = lazy(() => import("./pages/__PrivatePages/personal/info"));
const EngProfile = lazy(() => import("./pages/__PrivatePages/Profile/EngProfile"));
const Documents = lazy(() => import("./pages/__PrivatePages/Documents/RequiredDocs"));
const EditEng = lazy(() => import("./pages/__PrivatePages/personal/edit/EditEng"));
const AppealsPage = lazy(() => import("./pages/__PrivatePages/personal/appeals"));
function App() {

  const dispatch = useDispatch()
  const apiOracle = useOracleAxiosWithAuth();

  const { eng_id } = useSelector((state) => state.auth);

  const auth = useSelector((state) => state.auth);

  const engInfo = useSelector((state) => {
    // console.log("Redux State : ", state);
    return state.engInfo;
  });


  useEffect(() => {
    console.log("auth : ", auth)
    // console.log("user.role -->", role);
    console.log("Engineer -->", engInfo);
  }, [auth, engInfo])

  useEffect(() => {
    // Fetch engineer data from the updated API using Axios
    const fetchEngineerData = async () => {
      try {
        const response = await apiOracle.get(
          `engineers/detail/?arch_id=${eng_id}`
        );

        if (response.data) {
          const engineerData = response.data; // Since the response is a single object

          // Dispatch the add action with the response data
          dispatch(
            add({
              arch_id: engineerData.arch_id,
              arch_dgrade: engineerData.arch_dgrade,
              arch_ceno: engineerData.arch_ceno,
              arch_brno: engineerData.arch_brno,
              branch_no: engineerData.branch_no,
              branch_detail: {
                branch_no: engineerData.branch_no,
                branch_name: engineerData.branch_detail.branch_name,
              },
              arch_name: engineerData.arch_name,
              arch_lname: engineerData.arch_lname,
              arch_dbrith: engineerData.arch_dbrith,
              arch_djoin: engineerData.arch_djoin,
              arch_fname: engineerData.arch_fname,
              arch_mname: engineerData.arch_mname,
              arch_gender: engineerData.arch_gender,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching engineer data:", error);
      }
    };

    fetchEngineerData();
  }, [dispatch, eng_id]);

  // useEffect(() => {
  //   dispatch(add({
  //     "arch_id": 300363683,
  //     "arch_dgrade": "2001-01-01",
  //     "arch_ceno": 81496,
  //     "arch_brno": 9988,
  //     "branch_no": 6,
  //     "branch_detail": {
  //       "branch_no": 6,
  //       "branch_name": "حمص"
  //     },
  //     "arch_name": "رامي",
  //     "arch_lname": "عصفورة",
  //     "arch_dbrith": "1976-01-01",
  //     "arch_djoin": "2002-10-01"
  //   }))
  // }, [])

  return (
    <>
      <Routes>
        {(
          <Route element={<PrivateLayoutPages />}>
            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<Spinner page />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="/dashboard/settings"
              element={
                <Suspense fallback={<Spinner page />}>
                  <Settings />
                </Suspense>
              }
            />
            <Route
              path="/personal"
              element={
                <Suspense fallback={<Spinner page />}>
                  <Personal />
                </Suspense>
              }
            />
            <Route
              path="/dashboard/personal/prsn"
              element={
                <Suspense fallback={<Spinner page />}>
                  <Prsn />
                </Suspense>
              }
            />
            <Route
              path="/dashboard/practices"
              element={
                <Suspense fallback={<Spinner page />}>
                  <Practices />
                </Suspense>
              }
            />
            <Route
              path="/dashboard/yields/fix-req"
              element={
                <Suspense fallback={<Spinner page />}>
                  <FixReq />
                </Suspense>
              }
            />
            <Route
              path="/dashboard/yields/calculation"
              element={
                <Suspense fallback={<Spinner page />}>
                  <Calculation />
                </Suspense>
              }
            />
            <Route
              path="/dashboard/personal/info"
              element={
                <Suspense fallback={<Spinner page />}>
                  <Info />
                </Suspense>
              }
            />
            <Route
              path="/dashboard/personal/union"
              element={
                <Suspense fallback={<Spinner page />}>
                  <Union />
                </Suspense>
              }
            />
            <Route
              path="/dashboard/profile"
              element={
                <Suspense fallback={<Spinner page />}>
                  <EngProfile />
                </Suspense>
              }
            />
            <Route
              path="/dashboard/documents"
              element={
                <Suspense fallback={<Spinner page />}>
                  <Documents />
                </Suspense>
              }
            />
            <Route
              path="/dashboard/personal/edit-appeal"
              element={
                <Suspense fallback={<Spinner page />}>
                  <EditEng />
                </Suspense>
              }
            />
            <Route
              path="/dashboard/appeals"
              element={
                <Suspense fallback={<Spinner page />}>
                  <AppealsPage />
                </Suspense>
              }
            />
          </Route>
        )}

        <Route element={<PublicLayoutPages />}>
          <Route
            path="/login"
            element={
              <Suspense fallback={<Spinner page />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <Suspense fallback={<Spinner page />}>
                <Login />
              </Suspense>
            }
          />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="*"
            element={<Error404 navigateTo={"/"} timer={10000} />}
          />
        </Route>
      </Routes>
      {/* sun moon toggle dark light  */}
      <ThemeToggle />
      <Alert />
    </>
  );
}

export default App;
