import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOracleAxiosWithAuth } from '../Hooks/useOracleAxiosWithAuth';

const EngineerContext = createContext();

export const EngineerProvider = ({ children }) => {
    const apiOracle = useOracleAxiosWithAuth();
    const { eng_id } = useSelector((state) => state.auth);

    const [engineerInfoData, setEngineerInfoData] = useState(null);
    const [engineerPrsnData, setEngineerPrsnData] = useState(null);
    const [engineerUnionData, setEngineerUnionData] = useState(null);

    const [djangoEngineerInfoData, setDjangoEngineerInfoData] = useState(null);
    const [djangoEngineerPrsnData, setDjangoEngineerPrsnData] = useState(null);
    const [djangoEngineerUnionData, setDjangoEngineerUnionData] = useState(null);

    const [error, setError] = useState(null);

    const getUserInfo1 = async () => {
        try {
            const response = await apiOracle.get(`/engineers/${eng_id}/info/`);
            setEngineerInfoData(response.data);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    setError('لم يتم العثور على مهندس بالرقم المركزي المدخل .');
                } else if (error.response.status === 500) {
                    setError('Server error. Please try again later');
                } else {
                    setError('An error occurred. Please try again');
                }
            } else if (error.request) {
                setError('No response from server. Please try again later.');
            } else {
                setError('An error occurred. Please try again.');
            }

            setTimeout(() => setError(''), 3000);  // Clear error after 3 seconds
        }
    };

    const getUserInfo2 = async () => {
        try {
            // const response = await apiOracle.get(`engineers/combined/${user.username}/`); username is the ceno
            const response = await apiOracle.get(`/engineers/${eng_id}/personal/`);
            setEngineerPrsnData(response.data);

            console.log(response.data);
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error status code:', error.response.status);
                console.error('Error response data:', error.response.data);
                console.error('Error status text:', error.response.statusText);

                if (error.response.status === 404) {
                    setError('لم يتم العثور على مهندس بالرقم المركزي المدخل .');
                } else if (error.response.status === 500) {
                    setError('Server error. Please try again later');
                } else {
                    setError('An error occurred. Please try again');
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received:', error.request);
                setError('No response from server. Please try again later.');
            } else {
                // Something happened in setting up the request that triggered an error
                console.error('Error setting up request:', error.message);
                setError('An error occurred. Please try again.');
            }

            setTimeout(() => setError(''), 3000);  // Clear error after 3 seconds
        }
    };

    const getUserInfo3 = async () => {
        try {
            // const response = await apiOracle.get(`engineers/combined/${user.username}/`); username is the ceno
            const response = await apiOracle.get(`/engineers/${eng_id}/union/`);
            setEngineerUnionData(response.data);

            console.log(response.data);
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error status code:', error.response.status);
                console.error('Error response data:', error.response.data);
                console.error('Error status text:', error.response.statusText);

                if (error.response.status === 404) {
                    setError('لم يتم العثور على مهندس بالرقم المركزي المدخل .');
                } else if (error.response.status === 500) {
                    setError('Server error. Please try again later');
                } else {
                    setError('An error occurred. Please try again');
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received:', error.request);
                setError('No response from server. Please try again later.');
            } else {
                // Something happened in setting up the request that triggered an error
                console.error('Error setting up request:', error.message);
                setError('An error occurred. Please try again.');
            }

            setTimeout(() => setError(''), 3000);  // Clear error after 3 seconds
        }
    };

    const getUserInfo4 = async () => {
        try {
            const response = await apiOracle.get(`/django-engineers/${eng_id}/info/`);

            // Check if response data is an object and not null
            if (response.data && typeof response.data === 'object') {
                setDjangoEngineerInfoData(response.data);
            } else {
                setError('Unexpected response format. Please try again.');
                setTimeout(() => setError(''), 3000);  // Clear error after 3 seconds
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    setError('لم يتم العثور على مهندس بالرقم المركزي المدخل .');
                } else if (error.response.status === 500) {
                    setError('Server error. Please try again later');
                } else {
                    setError('An error occurred. Please try again');
                }
            } else if (error.request) {
                setError('No response from server. Please try again later.');
            } else {
                setError('An error occurred. Please try again.');
            }

            setTimeout(() => setError(''), 3000);  // Clear error after 3 seconds
        }
    };

    const getUserInfo5 = async () => {
        try {
            const response = await apiOracle.get(`/django-engineers/${eng_id}/personal/`);
            // Check if response data is an object and not null
            if (response.data && typeof response.data === 'object') {
                setDjangoEngineerPrsnData(response.data);
            } else {
                setError('Unexpected response format. Please try again.');
                setTimeout(() => setError(''), 3000);  // Clear error after 3 seconds
            }

        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    setError('لم يتم العثور على مهندس بالرقم المركزي المدخل .');
                } else if (error.response.status === 500) {
                    setError('Server error. Please try again later');
                } else {
                    setError('An error occurred. Please try again');
                }
            } else if (error.request) {
                setError('No response from server. Please try again later.');
            } else {
                setError('An error occurred. Please try again.');
            }

            setTimeout(() => setError(''), 3000);  // Clear error after 3 seconds
        }
    };

    const getUserInfo6 = async () => {
        try {
            const response = await apiOracle.get(`/django-engineers/${eng_id}/union/`);
            // Check if response data is an object and not null
            if (response.data && typeof response.data === 'object') {
                setDjangoEngineerUnionData(response.data);
            } else {
                setError('Unexpected response format. Please try again.');
                setTimeout(() => setError(''), 3000);  // Clear error after 3 seconds
            }

        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    setError('لم يتم العثور على مهندس بالرقم المركزي المدخل .');
                } else if (error.response.status === 500) {
                    setError('Server error. Please try again later');
                } else {
                    setError('An error occurred. Please try again');
                }
            } else if (error.request) {
                setError('No response from server. Please try again later.');
            } else {
                setError('An error occurred. Please try again.');
            }

            setTimeout(() => setError(''), 3000);  // Clear error after 3 seconds
        }
    };

    useEffect(() => {
        getUserInfo1();
        getUserInfo2();
        getUserInfo3();
        getUserInfo4();
        getUserInfo5();
        getUserInfo6();
    }, [eng_id]); // Dependency array includes eng_id

    return (
        <EngineerContext.Provider value={{ engineerInfoData, engineerPrsnData, engineerUnionData, djangoEngineerInfoData, djangoEngineerPrsnData, djangoEngineerUnionData, error }}>
            {children}
        </EngineerContext.Provider>
    );
};

// Custom hook for using the EngineerContext
export const useEngineer = () => {
    return useContext(EngineerContext);
};
